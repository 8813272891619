import apiCall from 'app/libs/utils/api/apiCall';
import { buildUrl } from 'libs/utils/api';
import { OperatorResponse, BasicResponse } from './types';
import { SharedErrors } from 'libs/utils/api/types';

// /api/v1/users
export const usersScope = (path?) => `/users${path || ''}`;

// /api/v1/users/current
export const getCurrentUser = () => {
  const url = buildUrl(usersScope('/current'));
  return apiCall.get<OperatorResponse, SharedErrors>({ url });
};

// /api/v1/users/by_order/:order_number
export const getUserByOrder = (orderNumber: string) => {
  const url = buildUrl(usersScope(`/by_order/${orderNumber}`));
  return apiCall.get<OperatorResponse, SharedErrors>({ url });
};

// /api/v1/users/sign-in
export const postUsersSignIn = user => {
  const url = buildUrl(usersScope('/sign-in'));
  return apiCall.post<OperatorResponse, SharedErrors>({ url, data: { spree_user: user } });
};

// /api/v1/users/sign-out
export const getUsersSignOut = () => {
  const url = buildUrl(usersScope('/sign-out'));
  return apiCall.post<BasicResponse, SharedErrors>({ url });
};

// /api/v1/users
export const postUsers = user => {
  const url = usersScope();
  return apiCall.post<OperatorResponse, SharedErrors>({ url, data: { spree_user: user } });
};

// POST /api/v1/users/confirmation
export const postUsersConfirmation = user => {
  const url = buildUrl(usersScope('/confirmation'));
  return apiCall.post<BasicResponse, SharedErrors>({ url, data: { spree_user: user } });
};

// POST /api/v1/users/password
export const postUsersPassword = user => {
  const url = buildUrl(usersScope('/password'));
  return apiCall.post<BasicResponse, SharedErrors>({ url, data: { spree_user: user } });
};

// PATCH /api/v1/users/password
export const updateUsersPassword = params => {
  const url = buildUrl(usersScope('/password'));
  return apiCall.patch<OperatorResponse, SharedErrors>({ url, data: { spree_user: params } });
};
