import trim from 'lodash-es/trim';
import lowerCase from 'lodash-es/lowerCase';

export const convertToValidId = (str: string, id?: string) => {
  const trimmed = trim(str);
  const lowered = lowerCase(trimmed);
  const dashed = lowered.replace(/ /g, '-');
  const final = id ? `${dashed}-${id}` : dashed;
  return final;
};
