import React from 'react';
import cn from 'classnames';
import {
  InformationCircleIcon,
  ExclamationCircleIcon,
  XCircleIcon,
  CheckCircleIcon,
} from '@heroicons/react/24/outline';
import { TypeOptions } from 'react-toastify';

interface ToastBodyProps {
  message: string;
  description?: string;
  type?: TypeOptions;
}

const Toast = ({ message, description, type }: ToastBodyProps) => {
  let icon = (
    <XCircleIcon
      className={cn(
        'text-red-500',
        'ml-1 h-5 w-5 transform transition duration-150 group-hover:text-red-500',
      )}
    />
  );
  switch (type) {
    case 'success':
      icon = (
        <CheckCircleIcon
          className={cn(
            'text-green-500',
            'ml-1 h-5 w-5 transform transition duration-150 group-hover:text-green-500',
          )}
        />
      );
      break;
    case 'error':
      icon = (
        <XCircleIcon
          className={cn(
            'text-red-500',
            'ml-1 h-5 w-5 transform transition duration-150 group-hover:text-red-500',
          )}
        />
      );
      break;
    case 'info':
      icon = (
        <InformationCircleIcon
          className={cn(
            'text-blue-500',
            'ml-1 h-5 w-5 transform transition duration-150 group-hover:text-blue-500',
          )}
        />
      );
      break;
    case 'warning':
      icon = (
        <ExclamationCircleIcon
          className={cn(
            'text-yellow-500',
            'ml-1 h-5 w-5 transform transition duration-150 group-hover:text-yellow-500',
          )}
        />
      );
      break;
    default:
      icon = (
        <InformationCircleIcon
          className={cn(
            'text-blue-500',
            'ml-1 h-5 w-5 transform transition duration-150 group-hover:text-blue-500',
          )}
        />
      );
      break;
  }

  return (
    <div
      data-cy="alertMessage"
      className={cn(
        'mr-8 flex text-sm font-semibold leading-5',
        type === 'success' ? 'text-green-500' : '',
        type === 'warning' ? 'text-yellow-500' : '',
        type === 'info' ? 'text-blue-500' : '',
        type === 'error' ? 'text-red-500' : '',
        description ? 'mb-2' : 'items-center',
      )}
    >
      <div className={cn(description ? 'self-start' : 'flex items-center', 'mr-3')}>{icon}</div>
      <div className="flex-col">
        <div className={cn(description ? 'mb-2' : '', 'font-hvBold text-sm')}>{message}</div>
        {description && <div className="text-xs font-medium leading-4 text-gray-500"> {description}</div>}
      </div>
    </div>
  );
};

export default Toast;
