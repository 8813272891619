/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-unknown-property */
import * as React from 'react';
import cn from 'classnames';
import css from './Image.scss';

interface Props {
  title?: string;
  alt: string;
  id?: string;
  className?: string;
  style?: object;
  width?: string | number;
  height?: string | number;
  onClick?: () => void;
  onKeyDown?: () => void;
  url?: string;
  ariaHidden?: boolean;
  responsive?: boolean;
  responsiveProportion?: number;
  lazyload?: boolean;
  dataCy?: string;
  fetchpriority?: 'high' | 'low' | 'auto';
}

const Image = ({
  id,
  className,
  style,
  title,
  alt,
  width,
  height,
  onClick,
  onKeyDown,
  ariaHidden,
  url,
  responsive,
  responsiveProportion,
  lazyload,
  dataCy,
  fetchpriority,
  ...otherProps
}: Props) =>
  responsive ? (
    <div
      data-cy={dataCy}
      className="relative"
      style={{ paddingBottom: `calc(100% * ${responsiveProportion})` }}
    >
      <img
        id={id}
        className={cn(css.Image, className)}
        style={style}
        alt={alt}
        onClick={onClick}
        onKeyDown={onKeyDown}
        height={height}
        width={width}
        src={url}
        aria-hidden={ariaHidden}
        title={title}
        loading={lazyload ? 'lazy' : 'eager'}
        {...otherProps}
      />
    </div>
  ) : (
    <img
      id={id}
      className={cn(css.Image, className)}
      style={style}
      alt={alt}
      onClick={onClick}
      onKeyDown={onKeyDown}
      height={height}
      width={width}
      src={url}
      aria-hidden={ariaHidden}
      title={title}
      loading={lazyload ? 'lazy' : 'eager'}
      data-cy={dataCy}
      fetchpriority={fetchpriority}
      {...otherProps}
    />
  );

export default Image;
