import * as React from 'react';
import cn from 'classnames';

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {}

const Span = ({ className, children, ...otherProps }: Props) => (
  <span className={cn(className)} {...otherProps}>
    {children}
  </span>
);

export default Span;
