/* eslint-disable react/destructuring-assignment */
import * as React from 'react';
import cn from 'classnames';

interface State {
  isOpen: boolean;
}

interface Props {
  title: (state: State) => React.ReactNode;
  noPadding?: boolean;
  children?: React.ReactNode;
}

class MobileMenuBlock extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  toggle = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  };

  render() {
    return (
      <div>
        <div role="button" tabIndex={0} onClick={this.toggle} onKeyDown={this.toggle} className="p-0">
          {this.props.title(this.state)}
        </div>
        <div
          className={cn(this.state.isOpen ? 'block' : 'hidden', this.props.noPadding ? 'p-0' : 'pt-2 px-2')}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default MobileMenuBlock;
