import * as React from 'react';

import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';
import colors from 'styleguide/styles/colors';

const IconArrowDown = ({
  title = 'ArrowDown',
  color = 'dark',
  viewBoxWidth = 20,
  viewBoxHeight = 12,
  ...otherProps
}: SvgProps) => (
  <SvgIcon {...{ title, viewBoxWidth, viewBoxHeight, color, ...otherProps }}>
    <path d="M20 1L9.757 11 0 1.474" stroke={colors[color]} strokeWidth="2" fill="none" fillRule="evenodd" />
  </SvgIcon>
);

export default IconArrowDown;
