import * as React from 'react';
import classNames from 'classnames';
import { Color, Underline } from './types';

import { computeLinkClassName } from './utils';

interface Props
  extends React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
  href: string;
  targetBlank?: boolean;
  color: Color;
  underline: Underline;
  disabled?: boolean;
  name?: string;
  noFollow?: boolean;
}

const A = ({
  className,
  children,
  color,
  underline,
  targetBlank,
  href,
  disabled,
  noFollow,
  ...otherProps
}: Props) =>
  disabled ? (
    <span className={classNames(computeLinkClassName({ color, underline }), className)} {...otherProps}>
      {children}
    </span>
  ) : (
    <a
      href={href}
      target={targetBlank && '_blank'}
      rel={[targetBlank && 'noopener noreferrer', noFollow && 'nofollow'].join(' ').trim()}
      className={classNames(computeLinkClassName({ color, underline }), className)}
      {...otherProps}
    >
      {children}
    </a>
  );

export default A;
