import * as React from 'react';
import { ReactOnRailsContext, Store, Options } from './types';

export interface AppContextType {
  store: Store;
  env: ReactOnRailsContext;
  options: Options;
  convertTimezoneKeepTime: (date: string | Date) => string;
  keepTimezoneConvertTime: (date: string | Date) => Date;
}

const AppContext = React.createContext<AppContextType>(null);

export default AppContext;
