import React, { createContext, useContext, useState, useMemo } from 'react';

interface HoverContextType {
  hoveredItem: number;
  setHoveredItem: (i: number) => void;
}

const HoverContext = createContext<HoverContextType>({
  hoveredItem: null,
  setHoveredItem: () => {},
});

export const useHoverContext = () => useContext(HoverContext);

const HoverProvider = ({ children }) => {
  const [hoveredItem, setHoveredItem] = useState(null);

  const value = useMemo(() => ({ hoveredItem, setHoveredItem }), [hoveredItem]);

  return <HoverContext.Provider value={value}>{children}</HoverContext.Provider>;
};

export default HoverProvider;
