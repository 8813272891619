// eslint-disable-next-line no-shadow
export enum Status {
  Ok = 'ok',
  ClientError = 'client-error',
  ServerError = 'server-error',
  AuthError = 'auth-error',
}

export interface OkApiResponse<T> {
  status: Status.Ok;
  payload: T;
}
export interface ServerErrorApiResponse {
  status: Status.ServerError;
}

export interface ClientErrorApiResponse<T> {
  status: Status.ClientError;
  payload: T;
}

export interface AuthErrorApiResponse<T> {
  status: Status.AuthError;
  payload: T;
}

export type Response<T, U> =
  | OkApiResponse<T>
  | ClientErrorApiResponse<U>
  | AuthErrorApiResponse<U>
  | ServerErrorApiResponse;

export type ApiResponse<T, U> = Promise<Response<T, U>>;

// views/api/v1/shared/errors.json.builder
export interface SharedErrors {
  message: string;
  errors: {
    [key: string]: string | string[];
    all?: string[];
  };
}
