import * as React from 'react';

// eslint-disable-next-line no-shadow
export enum Screen {
  SM = 'SM',
  MD = 'MD',
  LG = 'LG',
}

export type ScreenSize = keyof typeof Screen;

export interface ScreenSizeContextType {
  screenSize: ScreenSize;
}

const ScreenSizeContext = React.createContext<ScreenSizeContextType>(null);

export default ScreenSizeContext;
