import Enum from 'app/libs/utils/Enum';

export const EnvironmentType = {
  TEST: 'test',
  DEV: 'dev',
  PRODUCTION: 'production',
  STAGING: 'staging',
};

export default new Enum({
  TEST: 'test',
  DEV: 'dev',
  PRODUCTION: 'production',
  STAGING: 'staging',
});
