import * as React from 'react';
import cn from 'classnames';
import { ButtonColor, ButtonSize } from './types';
import css from './Button.scss';
import Link from 'styleguide/components/Links/Link';

export type ButtonType = 'link' | 'anchor';
export type ButtonStyle = 'normal' | 'bold' | 'medium';

type Target = {
  pathname: string;
  state: Record<string, string>;
};

export interface Props {
  buttonType: ButtonType | 'link';
  target: string | Target;
  color: ButtonColor;
  textStyle?: ButtonStyle;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  children: React.ReactNode;
  targetBlank?: boolean;
  onMouseOver?: () => void;
  onFocus?: () => void;
  disabled?: boolean;
  download?: boolean;
  outline?: boolean;
  size?: ButtonSize;
  dataCy?: string;
}

const ButtonAsNativeLink = ({
  buttonType,
  target,
  color,
  size,
  textStyle,
  disabled,
  outline,
  ...props
}: Props) =>
  buttonType === 'link' ? (
    <Link
      to={target}
      data-cy={props.dataCy}
      color="none"
      underline="none"
      unstyled
      className={cn(
        css.button,
        css[`color--${color}`],
        [css[`size--${size}`]],
        {
          [css[`size--${size}`]]: !!size,
          [css[`text--${textStyle}`]]: !!textStyle,
          [css[`disabled--${color}`]]: disabled,
          [css[`outline--${color}`]]: !!outline,
          [css[`outline--disabled--${color}`]]: !!outline,
        },
        props.className,
      )}
      onClick={props.onClick}
      onMouseOver={props.onMouseOver}
      disabled={disabled}
    >
      {props.children}
    </Link>
  ) : (
    <a
      data-cy={props.dataCy}
      className={cn(
        css.button,
        css[`color--${color}`],
        {
          [css[`size--${size}`]]: !!size,
          [css[`text--${textStyle}`]]: !!textStyle,
          [css[`disabled--${color}`]]: disabled,
          [css[`outline--${color}`]]: !!outline,
          [css[`outline--disabled--${color}`]]: !!outline,
        },
        props.className,
      )}
      onClick={props.onClick}
      download={props.download}
      href={target as string}
      target={props.targetBlank && '_blank'}
      rel={props.targetBlank && 'noopener noreferrer'}
      onMouseOver={props.onMouseOver}
      onFocus={props.onFocus}
    >
      {props.children}
    </a>
  );

export default ButtonAsNativeLink;
