import { isObject } from 'app/libs/utils';
import * as apiUtils from './index';
import { apiRoutes } from './route';
import defaults from 'lodash-es/defaults';
import {
  ApiResponse,
  Status,
  OkApiResponse,
  ClientErrorApiResponse,
  ServerErrorApiResponse,
} from 'libs/utils/api/types';
import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import toasts from 'utils/toast';
import { updateCsrfToken } from './index';

class ApiCalls {
  get<T, U>(params) {
    return this.callApi<T, U>('GET', params);
  }

  post<T, U>(params) {
    return this.callApi<T, U>('POST', params);
  }

  patch<T, U>(params) {
    return this.callApi<T, U>('PATCH', params);
  }

  put<T, U>(params) {
    return this.callApi<T, U>('PUT', params);
  }

  delete<T, U>(params) {
    return this.callApi<T, U>('DELETE', params);
  }

  callApi<T, U>(method: string, rawParams: string | object): ApiResponse<T, U> {
    const parsedParams = this.parseRawParams(method, rawParams);
    const reqUrl = this.buildReqUrl(parsedParams);
    const reqParams = this.buildReqParams(parsedParams);

    return fetch(reqUrl, reqParams)
      .then(res => this.parseResponse<T, U>(res))
      .catch(
        () =>
          ({
            status: Status.ServerError,
          }) as unknown as ServerErrorApiResponse,
      );
  }

  parseRawParams(method, rawParams) {
    return typeof rawParams === 'string' ? { method, url: rawParams } : { method, ...rawParams };
  }

  buildReqUrl(params) {
    if (!params.remote && !!params.unscoped) {
      return params.url;
    }
    return params.remote ? params.url : apiRoutes.apiScope(params.url);
  }

  buildReqParams(params): RequestInit {
    const reqParams: RequestInit = {};

    reqParams.method = params.method;

    if (params.data) {
      reqParams.body = this.buildReqBody(params.data);
    }

    if (!params.remote) {
      reqParams.credentials = 'same-origin';
      if (!(params.data instanceof FormData)) {
        const headers = defaults(
          {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-Token': apiUtils.getCsrfToken(),
          },
          params.headers,
        );
        reqParams.headers = headers;
      } else {
        const headers = defaults(
          {
            'X-CSRF-Token': apiUtils.getCsrfToken(),
          },
          params.headers,
        );
        reqParams.headers = headers;
      }
    } else if (params.remote && params.data && isObject(this.parseImmutableData(params.data))) {
      // TODO: comment out on production (commented because of CORS)
      // reqParams.headers = {
      //   'Content-Type': 'application/json',
      // };
    }

    return reqParams;
  }

  buildReqBody(data) {
    if (data instanceof FormData) {
      return data;
    }
    const reqBody = this.parseImmutableData(data);
    return isObject(reqBody)
      ? JSON.stringify(snakecaseKeys(reqBody, { deep: true, exclude: ['_destroy'] }))
      : reqBody;
  }

  parseImmutableData(data) {
    return typeof data.toJS === 'function' ? data.toJS() : data;
  }

  handleAlerts(alerts) {
    if (alerts) {
      alerts.map(alert => toasts.create(alert.message, alert.type));
    }
  }

  handleCsrfToken(csrfToken) {
    if (csrfToken) {
      updateCsrfToken(csrfToken);
    }
  }

  parseResponse<T, U>(response: Response): ApiResponse<T, U> {
    if (response.ok) {
      return response.json().then(res => {
        const payload = camelcaseKeys(res, { deep: true });
        this.handleAlerts(payload.alerts);
        this.handleCsrfToken(payload.csrfToken);

        return {
          status: Status.Ok,
          payload,
        } as unknown as OkApiResponse<T>;
      });
    }
    if (response.status === 422 || response.status === 400) {
      return response.json().then(res => {
        this.handleAlerts(res.alerts);

        return {
          status: Status.ClientError,
          payload: camelcaseKeys(res, { deep: true }),
        } as unknown as ClientErrorApiResponse<U>;
      });
    }
    if (response.status === 401) {
      return response.json().then(
        res =>
          ({
            status: Status.AuthError,
            payload: camelcaseKeys(res, { deep: true }),
          }) as unknown as ClientErrorApiResponse<U>,
      );
    }

    return response.json().then(res => {
      const isBadCsrfToken = response.status === 401 && res.message === 'Bad Authenticity Token';
      if (isBadCsrfToken) {
        window.location.reload();
      }
      toasts.error();

      return {
        status: Status.ServerError,
      } as unknown as ServerErrorApiResponse;
    });
  }
}

const apiCalls = new ApiCalls();

export default apiCalls;
