import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import classNames from 'classnames';

import { Color, Underline } from './types';
import { computeLinkClassName } from './utils';

type Target = {
  pathname: string;
  state: Record<string, string>;
};

interface Props {
  to: string | Target;
  color: Color;
  underline: Underline;
  disabled?: boolean;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  children?: React.ReactNode;
  onMouseOver?: (e: React.MouseEvent<HTMLElement>) => void;
  unstyled?: boolean;
  ariaLabel?: string;
}

const Link = ({ to, color, underline, disabled, className, unstyled, ariaLabel, ...otherProps }: Props) =>
  disabled ? (
    <span
      {...otherProps}
      className={!unstyled ? classNames(computeLinkClassName({ color, underline }), className) : className}
    />
  ) : (
    <RouterLink
      {...{ ...otherProps, to }}
      className={!unstyled ? classNames(computeLinkClassName({ color, underline }), className) : className}
      aria-label={ariaLabel}
    />
  );

export default Link;
