import * as React from 'react';
import classNames from 'classnames';

import { UiColor } from 'styleguide/styles/colors';
import { convertToValidId } from 'utils/dom';
import { UiSize } from 'styleguide/styles/sizes';

import css from './SvgIcon.scss';

export interface SvgProps {
  color?: UiColor | string;
  size?: UiSize | string;
  title?: string;
  viewBoxWidth?: number;
  viewBoxHeight?: number;
  viewBox?: string;
  id?: string;
  className?: string;
  onClick?: () => void;
  fill?: string;
}

interface SvgTemplateProps {
  color?: UiColor | string;
  size?: UiSize | string;
  children?: React.ReactNode;
  title: string;
  viewBoxWidth?: number;
  viewBoxHeight?: number;
  viewBox?: string;
  id?: string;
  className?: string;
  onClick?: () => void;
  fill?: string;
}

const SvgIcon = ({
  children,
  size = 'lg',
  title,
  viewBox,
  viewBoxWidth = 16,
  viewBoxHeight = 16,
  ...otherProps
}: SvgTemplateProps) => {
  const svgId = otherProps.id
    ? convertToValidId(`svg-${title}`, otherProps.id)
    : convertToValidId(`svg-${title}`);

  return (
    <svg
      {...otherProps}
      className={classNames(css[`size--${size}`], otherProps.className)}
      viewBox={viewBox || `0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={svgId}
    >
      <title id={svgId}>{title}</title>
      {children}
    </svg>
  );
};

export default SvgIcon;
