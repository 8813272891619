import * as React from 'react';

import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';
import colors from 'styleguide/styles/colors';

const IconBurger = ({
  title = 'Printivity Menu',
  color = 'blue',
  viewBoxWidth = 24,
  viewBoxHeight = 12,
  ...otherProps
}: SvgProps) => (
  <SvgIcon {...{ title, viewBoxWidth, viewBoxHeight, color, ...otherProps }}>
    <path
      fill={colors[color]}
      stroke="#323232"
      strokeLinecap="round"
      strokeWidth="1.6"
      d="M1.3 1.27h18.4m-18.4 6h18.4m-18.4 6h18.4"
    />
  </SvgIcon>
);

export default IconBurger;
