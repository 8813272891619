import * as React from 'react';
import cn from 'classnames';

const Container = ({
  className,
  style,
  children,
  ...otherProps
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => (
  <div className={cn('block w-full', className)} style={style} {...otherProps}>
    {children}
  </div>
);

export default Container;
