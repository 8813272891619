import * as React from 'react';
import { User, WalletPaymentSource } from './types';
import { Address } from 'bundles/App/pages/Account/Addresses/types';

export interface UserContextType {
  deleteCreditCard: (id: number) => void;
  updateCreditCards: (walletPaymentSources: WalletPaymentSource[]) => void;
  updateAddresses: (addresses: Address[], shipAddress?: Address, billAddress?: Address) => void;
  logIn: (user: User) => void;
  logOut: () => void;
  currentUser: User | null;
  isInitialMount: boolean;
  acknowledgeAddressValidation: () => void;
}

const UserContext = React.createContext<UserContextType>(null);

export default UserContext;
