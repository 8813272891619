import * as React from 'react';
import cn from 'classnames';

const IfDesktop = ({
  className,
  children,
  ...otherProps
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => (
  <div className={cn('hidden w-full lg:block', className)} {...otherProps}>
    {children}
  </div>
);

export default IfDesktop;
