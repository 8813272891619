import * as React from 'react';
import cn from 'classnames';

const IfTabletOrMobile = ({
  className,
  children,
  ...otherProps
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => (
  <div className={cn('block w-full lg:!hidden', className)} {...otherProps}>
    {children}
  </div>
);

export default IfTabletOrMobile;
