import * as React from 'react';
import cn from 'classnames';

export interface ChipProps {
  className?: string;
  title: string | React.ReactNode;
  textColor?: string;
  backgroundColor?: string;
  borderColor?: string;
  onClick?: () => void;
}

const Chip = ({ title, textColor, backgroundColor, borderColor, onClick, ...props }: ChipProps) => (
  <div
    className={cn(
      textColor,
      backgroundColor,
      borderColor,
      'flex items-center rounded-full px-5 py-2 font-hvMedium text-sm',
      props.className,
    )}
    onClick={onClick}
    onKeyDown={event => {
      if (event.key === 'Enter' || event.key === ' ') {
        onClick();
      }
    }}
    role="button"
    tabIndex={0}
  >
    {title}
  </div>
);

export default Chip;
