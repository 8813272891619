import * as React from 'react';
import cn from 'classnames';
import css from './Loader.scss';
import sizes, { UiSize } from 'styleguide/styles/sizes';
import colors, { UiColor } from 'styleguide/styles/colors';

interface Props {
  size?: UiSize;
  color?: UiColor;
  className?: string;
  dataCy?: string;
}

const Loader = ({ size = 'md', color = 'blue', className, dataCy }: Props) => (
  <div
    data-cy={dataCy}
    className={cn(css.loader, className)}
    style={{ borderTopColor: colors[color], width: `${sizes[size] * 2}px`, height: `${sizes[size] * 2}px` }}
  />
);

export default Loader;
