import * as React from 'react';
import { Order } from 'api/orders/types';

export interface SimplyShipError {
  message: string;
}

export interface OrderContextType {
  order: Order;
  dispatch: React.Dispatch<Record<'type' | string, unknown>>; // TODO: check if we want to be more elaborate
}

const OrderContext = React.createContext<OrderContextType>(null);

export default OrderContext;
