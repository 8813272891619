// @flow
import { stringify } from 'qs';
import { pickBy, find, identity } from 'lodash-es';

import { isTest } from 'libs/utils/env';

export function isJsonContentType(response: Response) {
  const contentType = response.headers.get('Content-Type');

  if (contentType === null) {
    return false;
  }

  return contentType.indexOf('application/json') !== -1;
}

export function buildUrl(path: string, query?: object) {
  // $FlowFixMe
  const filteredQuery = pickBy(query, identity);
  return query ? `${path}?${stringify(filteredQuery, { arrayFormat: 'brackets' })}` : `${path}`;
}

export function getCsrfToken() {
  if (isTest || typeof document === 'undefined') {
    return null;
  }

  const metas = document.querySelectorAll('meta');
  const token = find(metas, { name: 'csrf-token' });

  if (!token) {
    throw new Error('Missing CSRF TOKEN in head');
  }

  return token.content;
}

export function updateCsrfToken(token: string) {
  if (isTest || typeof document === 'undefined') {
    return null;
  }

  const metaCsrfToken = document.querySelector('meta[name="csrf-token"]');
  if (!metaCsrfToken) {
    throw new Error('Missing CSRF TOKEN in head');
  }
  // $FlowFixMe
  return document.querySelector('meta[name="csrf-token"]').setAttribute('content', token);
}
