import * as React from 'react';
import { HelmetProvider } from 'react-helmet-async';

type HelmetContextType = {
  helmet: {
    base: React.ReactNode[];
    link: React.ReactNode[];
    meta: React.ReactNode[];
    noscript: React.ReactNode[];
    script: React.ReactNode[];
    style: React.ReactNode[];
    title: React.ReactNode[];
  };
};

interface Props {
  children: React.ReactNode;
  includeContext?: boolean;
}

export const helmetContext: HelmetContextType = {
  helmet: { base: [], link: [], meta: [], noscript: [], script: [], style: [], title: [] },
};

const HelmetContextContainer = ({ children, includeContext }: Props) =>
  includeContext ? (
    <HelmetProvider context={helmetContext}>{children}</HelmetProvider>
  ) : (
    <HelmetProvider>{children}</HelmetProvider>
  );

export default HelmetContextContainer;
