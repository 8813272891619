export type UiSize = 'content' | 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

const sizes = {
  xxs: 9,
  xs: 12,
  sm: 16,
  md: 18,
  lg: 20,
  xl: 36,
  xxl: 52,
};

export default sizes;
