import * as React from 'react';
import cn from 'classnames';

import Container from './Container';
import Row from './Row';
import Col from './Col';

interface Props {
  children: React.ReactNode;
  className?: string;
  noPadding?: boolean;
  fullWidth?: boolean;
}

const Grid = ({ ...props }: Props) => (
  <div
    className={cn(
      'flex flex-col',
      props.fullWidth ? 'm-0 max-w-full' : 'mx-auto my-0 max-w-base',
      props.noPadding ? 'px-0' : 'px-1 md:px-3',
      props.className,
    )}
  >
    {props.children}
  </div>
);

Grid.Container = Container;
Grid.Row = Row;
Grid.Col = Col;

export default Grid;
