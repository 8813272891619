import * as React from 'react';

import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';
import colors from 'styleguide/styles/colors';

const IconClose = ({
  title = 'Close',
  color = 'dark',
  viewBoxWidth = 11,
  viewBoxHeight = 12,
  ...otherProps
}: SvgProps) => (
  <SvgIcon {...{ title, viewBoxWidth, viewBoxHeight, color, ...otherProps }}>
    <g stroke={colors[color]} strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="square">
      <path d="M10.23.818L.533 10.516M10.23 10.516L.533.818" />
    </g>
  </SvgIcon>
);

export default IconClose;
