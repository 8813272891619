import * as React from 'react';
import cn from 'classnames';

const Row = ({
  children,
  className,
  ...otherProps
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => (
  <div className={cn('-mx-1 flex flex-wrap md:-mx-3 md:justify-center', className)} {...otherProps}>
    {children}
  </div>
);

export default Row;
