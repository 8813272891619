import * as React from 'react';
import cn from 'classnames';

import css from './Col.scss';

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xlg?: number;
  xlg2?: number;
  xlg3?: number;
  xlg4?: number;
  hiddenSm?: 'up' | 'down';
  hiddenMd?: 'up' | 'down';
  hiddenLg?: 'up' | 'down';
  hiddenXlg?: 'up' | 'down';
}

const Col = ({
  xs,
  sm,
  md,
  lg,
  xlg,
  xlg2,
  xlg3,
  xlg4,
  hiddenSm,
  hiddenMd,
  hiddenLg,
  hiddenXlg,
  children,
  className,
  ...props
}: Props) => (
  <div
    className={cn('w-full px-1 md:px-3', className, {
      [css[`Col--4xlg-${xlg4}`]]: !!xlg4,
      [css[`Col--3xlg-${xlg3}`]]: !!xlg3,
      [css[`Col--2xlg-${xlg2}`]]: !!xlg2,
      [css[`Col--xlg-${xlg}`]]: !!xlg,
      [css[`Col--lg-${lg}`]]: !!lg,
      [css[`Col--md-${md}`]]: !!md,
      [css[`Col--sm-${sm}`]]: !!sm,
      [css[`Col--xs-${xs}`]]: !!xs,
      'sm:!hidden': hiddenSm === 'up',
      '-sm:!hidden': hiddenSm === 'down',
      'md:!hidden': hiddenMd === 'up',
      '-md:!hidden': hiddenMd === 'down',
      'lg:!hidden': hiddenLg === 'up',
      '-lg:!hidden': hiddenLg === 'down',
      'xl:!hidden': hiddenXlg === 'up',
      '-xl:!hidden': hiddenXlg === 'down',
    })}
    {...props}
  >
    {children}
  </div>
);

export default Col;
