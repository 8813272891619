import * as React from 'react';
import { toast, ToastOptions, TypeOptions } from 'react-toastify';
import IconClose from 'styleguide/icons/IconClose';
import ToastBody from 'styleguide/components/Toast/Toast';

const colorByType = {
  success: 'green-500',
  error: 'red-500',
  info: 'blue',
};

class Toast {
  create = (message, type, description = '', options: ToastOptions = {}) => {
    toast(<ToastBody type={type} message={message} description={description} />, {
      theme: 'light',
      type,
      containerId: 'autoclose',
      closeButton: options.containerId && options.containerId !== 'autoclose' && (
        <IconClose className={description ? 'mt-[10px] !h-2 !w-2 self-start' : '!h-2 !w-2 self-center'} />
      ),
      className: `border border-solid border-${colorByType[type]} shadow-md shadow-${colorByType[type]} !rounded-xl !pr-4 !min-h-[56px] !max-w-[360px]`,
      ...options,
    });
  };

  error = (
    message = 'There has been an error when processing your request.',
    description = '',
    options: ToastOptions = {},
  ) => {
    toast.error(<ToastBody type="error" message={message} description={description} />, {
      theme: 'light',
      containerId: 'autoclose',
      closeButton: options.containerId && options.containerId !== 'autoclose' && (
        <IconClose className={description ? 'mt-[10px] !h-2 !w-2 self-start' : '!h-2 !w-2 self-center'} />
      ),
      className:
        'border border-solid border-red-500 shadow-md shadow-red-500 !rounded-xl !pr-4 !min-h-[56px] !max-w-[360px]',
      ...options,
    });
  };

  success = (message, description = '', options: ToastOptions = {}) => {
    toast.success(<ToastBody type="success" message={message} description={description} />, {
      theme: 'light',
      containerId: 'autoclose',
      closeButton: options.containerId && options.containerId !== 'autoclose' && (
        <IconClose className={description ? 'mt-[10px] !h-2 !w-2 self-start' : '!h-2 !w-2 self-center'} />
      ),
      className:
        'border border-solid border-green-500 shadow-md shadow-green-500 !rounded-xl !pr-4 !min-h-[56px] !max-w-[360px]',
      ...options,
    });
  };

  banner = (message: string, type: TypeOptions = 'info', description = '', options: ToastOptions = {}) => {
    toast(<ToastBody type={type} message={message} description={description} />, {
      theme: 'light',
      type,
      containerId: 'autoclose',
      closeButton: options.containerId && options.containerId !== 'autoclose' && (
        <IconClose className={description ? 'mt-[10px] !h-2 !w-2 self-start' : '!h-2 !w-2 self-center'} />
      ),
      className:
        'border border-solid border-blue shadow-md shadow-blue !rounded-xl !pr-4 !min-h-[56px] !max-w-[360px]',
      ...options,
    });
  };

  event = (message: string, options: ToastOptions = {}) => {
    toast(message, { theme: 'dark', containerId: 'events', ...options });
  };
}

const toasts = new Toast();

export default toasts;
