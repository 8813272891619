import * as React from 'react';
import classNames from 'classnames';

import ButtonLike from '../ButtonLike/ButtonLike';

import { Color, Underline } from './types';

import { computeLinkClassName } from './utils';

interface Props {
  color: Color;
  underline: Underline;
  disabled?: boolean;
  className?: string;
  setRef?: (ref: HTMLButtonElement) => void;
  onClick: () => void;
  children: React.ReactNode;
}

const LinkLike = ({
  color,
  underline,
  disabled,
  className,
  setRef,
  onClick,
  children,
  ...otherProps
}: Props) => (
  <ButtonLike {...{ disabled, setRef, onClick, ...otherProps }}>
    <span className={classNames(computeLinkClassName({ color, underline }), className)}>{children}</span>
  </ButtonLike>
);

export default LinkLike;
