import loadable from '@loadable/component';
import retry from 'utils/retry';

export const IconAdaptability = loadable(() => retry(() => import('./IconAdaptability')));
export const IconApproveDesign = loadable(() => retry(() => import('./IconApproveDesign')));

export const IconArrowDownSolid = loadable(() => retry(() => import('./IconArrowDownSolid')));
export const IconArrowLeft = loadable(() => retry(() => import('./IconArrowLeft')));
export const IconArrowRight = loadable(() => retry(() => import('./IconArrowRight')));
export const IconArrowUp = loadable(() => retry(() => import('./IconArrowUp')));
export const IconBook = loadable(() => retry(() => import('./IconBook')));
export const IconBrochure = loadable(() => retry(() => import('./IconBrochure')));
export const IconBusinessCard = loadable(() => retry(() => import('./IconBusinessCard')));
export const IconClose = loadable(() => retry(() => import('./IconClose')));
export const IconDimension = loadable(() => retry(() => import('./IconDimension')));
export const IconFileCheck = loadable(() => retry(() => import('./IconFileCheck')));
export const IconFonts = loadable(() => retry(() => import('./IconFonts')));
export const IconFreeToFail = loadable(() => retry(() => import('./IconFreeToFail')));
export const IconHumility = loadable(() => retry(() => import('./IconHumility')));
export const IconMail = loadable(() => retry(() => import('./IconMail')));
export const IconNotebook = loadable(() => retry(() => import('./IconNotebook')));
export const IconPhone = loadable(() => retry(() => import('./IconPhone')));
export const IconQuality = loadable(() => retry(() => import('./IconQuality')));
export const IconResolution = loadable(() => retry(() => import('./IconResolution')));
export const IconSearch = loadable(() => retry(() => import('./IconSearch')));
export const IconService = loadable(() => retry(() => import('./IconService')));
export const IconShipping = loadable(() => retry(() => import('./IconShipping')));
export const IconSpeed = loadable(() => retry(() => import('./IconSpeed')));
export const IconTooltipInfo = loadable(() => retry(() => import('./IconTooltipInfo')));
export const IconTooltipQuestion = loadable(() => retry(() => import('./IconTooltipQuestion')));
export const IconCropMark = loadable(() => retry(() => import('./IconCropMark')));
export const IconLock = loadable(() => retry(() => import('./IconLock')));
export const IconCuriosity = loadable(() => retry(() => import('./IconCuriosity')));
export const IconBeDifferent = loadable(() => retry(() => import('./IconBeDifferent')));
export const IconTooltipClose = loadable(() => retry(() => import('./IconTooltipClose')));
export const IconStar = loadable(() => retry(() => import('./IconStar')));
export const IconCheckmark = loadable(() => retry(() => import('./IconCheckmark')));
export const IconBleeds = loadable(() => retry(() => import('./IconBleeds')));
export const IconExclamationMark = loadable(() => retry(() => import('./IconExclamationMark')));
export const IconContactForHelp = loadable(() => retry(() => import('./IconContactForHelp')));
export const IconCheckMail = loadable(() => retry(() => import('./IconCheckMail')));
export const IconCircularFacebook = loadable(() => retry(() => import('./IconCircularFacebook')));
export const IconCircularLinkedIn = loadable(() => retry(() => import('./IconCircularLinkedIn')));
export const IconCircularTwitter = loadable(() => retry(() => import('./IconCircularTwitter')));
export const IconNext = loadable(() => retry(() => import('./IconNext')));
export const IconUpload = loadable(() => retry(() => import('./IconUpload')));
export const IconPhoneFilled = loadable(() => retry(() => import('./IconPhoneFilled')));
export const IconMegaphone = loadable(() => retry(() => import('./IconMegaphone')));
export const IconHappyCustomer = loadable(() => retry(() => import('./IconHappyCustomer')));
export const IconPriceMatch = loadable(() => retry(() => import('./IconPriceMatch')));
export const IconSatisfactionGuarantee = loadable(() => retry(() => import('./IconSatisfactionGuarantee')));
export const IconFreeResources = loadable(() => retry(() => import('./IconFreeResources')));
export const IconGraphicDesign = loadable(() => retry(() => import('./IconGraphicDesign')));
export const IconChooseDesign = loadable(() => retry(() => import('./IconChooseDesign')));
export const IconSelectTemplate = loadable(() => retry(() => import('./IconSelectTemplate')));
export const IconDesign = loadable(() => retry(() => import('./IconDesign')));
export const IconOne = loadable(() => retry(() => import('./IconOne')));
export const IconTwo = loadable(() => retry(() => import('./IconTwo')));
export const IconThree = loadable(() => retry(() => import('./IconThree')));
export const IconFour = loadable(() => retry(() => import('./IconFour')));
export const IconPriceTag = loadable(() => retry(() => import('./IconPriceTag')));
export const IconProfessionalDesignHelp = loadable(() => retry(() => import('./IconProfessionalDesignHelp')));
export const IconPeaceOfMind = loadable(() => retry(() => import('./IconPeaceOfMind')));
export const IconFastTurnarounds = loadable(() => retry(() => import('./IconFastTurnarounds')));
export const IconProgramming = loadable(() => retry(() => import('./IconProgramming')));
export const IconNoCost = loadable(() => retry(() => import('./IconNoCost')));
export const IconUnification = loadable(() => retry(() => import('./IconUnification')));
export const IconAnalytics = loadable(() => retry(() => import('./IconAnalytics')));
export const IconCloudSpeed = loadable(() => retry(() => import('./IconCloudSpeed')));
export const IconConvenience = loadable(() => retry(() => import('./IconConvenience')));
export const IconScalability = loadable(() => retry(() => import('./IconScalability')));
export const IconSecurity = loadable(() => retry(() => import('./IconSecurity')));
export const IconStability = loadable(() => retry(() => import('./IconStability')));
export const IconClockRotate = loadable(() => retry(() => import('./IconClockRotate')));
export const IconInConsistentQuality = loadable(() => retry(() => import('./IconInConsistentQuality')));
export const IconNoCoin = loadable(() => retry(() => import('./IconNoCoin')));
export const IconPlatform = loadable(() => retry(() => import('./IconPlatform')));
export const IconSlow = loadable(() => retry(() => import('./IconSlow')));
export const IconUnScalable = loadable(() => retry(() => import('./IconUnScalable')));
export const IconDrawnEllipse = loadable(() => retry(() => import('./IconDrawnEllipse')));
export const IconArrowFull = loadable(() => retry(() => import('./IconArrowFull')));
