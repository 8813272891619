export type UiColor =
  | 'black'
  | 'white'
  | 'dark'
  | 'blue'
  | 'red'
  | 'brightRed'
  | 'lightBlue'
  | 'lightGray'
  | 'mediumGray'
  | 'darkGray'
  | 'gray'
  | 'none'
  | 'green'
  | 'facebookBlue'
  | 'googleOrange'
  | 'yellow';

const colors = {
  black: '#000',
  white: '#fff',
  dark: '#323232',
  blue: '#00AEEF',
  red: '#EF4129',
  brightRed: '#EF0000',
  lightBlue: '#CCEFFC',
  lightGray: '#EAEAEA',
  gray: '#DDD',
  mediumGray: '#C4C4C4',
  darkGray: '#888',
  green: '#0CCC5A',
  facebookBlue: '#4267b2',
  googleOrange: '#e7711b',
  yellow: '#f1c40f',
};

export default colors;
