import { BasicOrderCheckout, PurchaseOrder } from './orders/types';

export interface GtmProduct {
  item_name: string;
  item_id: string;
}

export interface GtmProductWithPrice extends GtmProduct {
  price: number;
  quantity: number;
}

export interface GtmDataLayerValue {
  event: string;
  ecommerce?: {
    category?: string;
    items?: GtmProduct[];
  };
  properties?: Record<string, unknown>;
  eventCallback?: () => void;
}

declare global {
  interface Window {
    dataLayer: GtmDataLayerValue[];
  }
}

const waitForDataLayer = (timeout = 7000, interval = 200): Promise<void> =>
  new Promise(resolve => {
    const startTime = Date.now();
    const checkDataLayer = () => {
      if (window.dataLayer) {
        resolve();
      } else if (Date.now() - startTime >= timeout) {
        resolve();
      } else {
        setTimeout(checkDataLayer, interval);
      }
    };

    checkDataLayer();
  });

const push = async (data: GtmDataLayerValue): Promise<boolean> => {
  try {
    await waitForDataLayer();
    window.dataLayer.push(data);
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const productPage = (category: string, items: GtmProduct[]): Promise<void> =>
  new Promise(resolve => {
    const data = {
      event: 'view_product_page',
      ecommerce: {
        category,
        items,
      },
    };

    push(data)
      .then(() => {
        resolve();
      })
      .catch(error => {
        resolve(error);
      });

    resolve();
  });

export const formSubmit = (event: string): Promise<void> =>
  new Promise(resolve => {
    const data = {
      event,
    };

    push(data)
      .then(() => {
        resolve();
      })
      .catch(error => {
        resolve(error);
      });

    resolve();
  });

export const productSelection = (category: string, item: GtmProduct): Promise<void> =>
  new Promise(resolve => {
    const data = {
      event: 'view_item',
      ecommerce: {
        category,
        items: [item],
      },
    };

    push(data)
      .then(() => {
        resolve();
      })
      .catch(error => {
        resolve(error);
      });

    resolve();
  });

export const addToCart = (item: GtmProductWithPrice): Promise<void> =>
  new Promise(resolve => {
    const data = {
      event: 'add_to_cart',
      ecommerce: {
        items: [item],
      },
    };

    push(data)
      .then(() => {
        resolve();
      })
      .catch(error => {
        resolve(error);
      });

    resolve();
  });

export const removeFromCart = (item: GtmProductWithPrice): Promise<void> =>
  new Promise(resolve => {
    const data = {
      event: 'remove_from_cart',
      ecommerce: {
        items: [item],
      },
    };

    push(data)
      .then(() => {
        resolve();
      })
      .catch(error => {
        resolve(error);
      });

    resolve();
  });

export const checkout = (orderFields: BasicOrderCheckout, items: GtmProductWithPrice[]): Promise<void> =>
  new Promise(resolve => {
    const data = {
      event: 'checkout',
      ecommerce: {
        ...orderFields,
        items,
      },
      eventCallback: null,
    };

    push(data)
      .then(() => {
        resolve();
      })
      .catch(error => {
        resolve(error);
      });

    resolve();
  });

export const purchase = (orderFields: PurchaseOrder, items: GtmProductWithPrice[]): Promise<void> =>
  new Promise(resolve => {
    const data = {
      event: 'purchase',
      ecommerce: {
        ...orderFields,
        items,
      },
    };

    push(data)
      .then(() => {
        resolve();
      })
      .catch(error => {
        resolve(error);
      });

    resolve();
  });

export const clickCarousel = (item: GtmProduct, properties: Record<string, unknown>): Promise<void> =>
  new Promise(resolve => {
    const data = {
      event: 'carousel_click',
      ecommerce: {
        ...properties,
        items: [item],
      },
    };

    push(data)
      .then(() => {
        resolve();
      })
      .catch(error => {
        resolve(error);
      });

    resolve();
  });
export const clickRelatedProduct = (item: GtmProduct): Promise<void> =>
  new Promise(resolve => {
    const data = {
      event: 'click_related_product',
      ecommerce: {
        items: [item],
      },
    };

    push(data)
      .then(() => {
        resolve();
      })
      .catch(error => {
        resolve(error);
      });

    resolve();
  });

const pushEvent = (event: string, properties: Record<string, unknown> = {}): Promise<void> =>
  new Promise(resolve => {
    const data = {
      event,
      ...properties,
    };

    push(data);
    resolve();
  });

export const clickSecondaryNavbar = (properties: Record<string, unknown>): Promise<void> =>
  pushEvent('click_secondary_navbar', properties);

export const clickTemplate = (properties: Record<string, unknown>): Promise<void> =>
  pushEvent('template_click', properties);

export const clickFAQ = (properties: Record<string, unknown>): Promise<void> =>
  pushEvent('faq_click', properties);

export const clickRefreshQuote = (properties: Record<string, unknown>): Promise<void> =>
  pushEvent('refresh_quote_click', properties);

export const clickAddToCart = (properties: Record<string, unknown>): Promise<void> =>
  pushEvent('add_to_cart_click', properties);

export const clickEmailUs = (properties: Record<string, unknown>): Promise<void> =>
  pushEvent('email_us_click', properties);

export const clickGraphicDesignLink = (properties: Record<string, unknown>): Promise<void> =>
  pushEvent('graphic_design_link_click', properties);

export const clickField = (properties: Record<string, unknown>): Promise<void> =>
  pushEvent('field_click', properties);

export const clickSignUpPdp = (): Promise<void> => pushEvent('signup_click');

export const clickTooltip = (properties: Record<string, unknown>): Promise<void> =>
  pushEvent('tooltip_click', properties);

export const signUpSuccess = (properties: Record<string, unknown>): Promise<void> =>
  pushEvent('sign_up_success', properties);

export const pdpUserProperties = (properties: Record<string, unknown>): Promise<void> =>
  pushEvent('track_user_properties', properties);

export const cartAddressFieldClick = (properties: Record<string, unknown>): Promise<void> =>
  pushEvent('cart_address_field_click', properties);

export const cartDateFieldClick = (properties: Record<string, unknown>): Promise<void> =>
  pushEvent('cart_date_field_click', properties);

export const checkoutWithoutFileModal = (properties: Record<string, unknown>): Promise<void> =>
  pushEvent('checkout_without_file_modal', properties);

export const proceedToCheckoutModalActions = (properties: Record<string, unknown>): Promise<void> =>
  pushEvent('proceed_to_checkout_modal_actions', properties);

export const cartRemoveLineItem = (properties: Record<string, unknown>): Promise<void> =>
  pushEvent('cart_remove_line_item', properties);

export const cartUpdateDate = (properties: Record<string, unknown>): Promise<void> =>
  pushEvent('cart_update_date', properties);

export const cartUpdateTime = (properties: Record<string, unknown>): Promise<void> =>
  pushEvent('cart_update_time', properties);

export const cartEditItemDetails = (properties: Record<string, unknown>): Promise<void> =>
  pushEvent('cart_edit_line_item', properties);

export const cartFileUpload = (properties: Record<string, unknown>): Promise<void> =>
  pushEvent('cart_file_upload', properties);

export const cartPromoCode = (properties: Record<string, unknown>): Promise<void> =>
  pushEvent('cart_promo_code', properties);

export const cartEnterAddress = (properties: Record<string, unknown>): Promise<void> =>
  pushEvent('cart_enter_address', properties);
