// See docs/loadable-components.md for details regarding **.imports-X.** file extension & duplicate file structure.
import loadable from '@loadable/component';
import retry from 'utils/retry';

export const ProductDetailsPage = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ './pages/ProductDetailsPage/ProductDetailsPage')),
);
export const CategoryPage = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ './pages/CategoryPage/CategoryPage')),
);
export const UploadPage = loadable(() => retry(() => import('./pages/UploadPage/UploadPage')));
export const PaymentSuccessPage = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ 'bundles/App/pages/PaymentPortalPage/PaymentSuccessPage')),
);
export const PaymentPortalPage = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ 'bundles/App/pages/PaymentPortalPage/PaymentPortalPage')),
);
export const CreditCardsPage = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ './pages/Account/CreditCards/CreditCardsPage')),
);
export const TaxExemptionsPage = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ './pages/Account/TaxExemptions/TaxExemptionsPage')),
);
export const AddressesPage = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ './pages/Account/Addresses/AddressesPage')),
);
export const SettingsPage = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ './pages/Account/Settings/SettingsPage')),
);
export const ProofApprovalsPage = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ './pages/ProofApprovalsPage/ProofApprovalsPage')),
);
export const TrackingPage = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ './pages/Account/Tracking/TrackingPage')),
);
export const OrderHistoryPage = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ './pages/Account/OrderHistory/OrderHistoryPage')),
);
export const InformationPage = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ './pages/Company/InformationPage')),
);
export const HelpCenterPage = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ 'bundles/App/pages/HelpCenter/HelpCenter')),
);
export const SignUpPage = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ './pages/auth/SignUp/SignUpPage')),
);
export const SignInPage = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ './pages/auth/SignIn/SignInPage')),
);
export const ResetPasswordPage = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ './pages/auth/ResetPassword/ResetPasswordPage')),
);
export const ResendConfirmationPage = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ './pages/auth/ResendConfirmation/ResendConfirmationPage')),
);
export const CartPage = loadable(() => retry(() => import(/* webpackPrefetch: true */ './pages/CartPage')));
export const CheckoutPage = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ './pages/CheckoutPage')),
);
export const ProductListingPage = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ './pages/ProductListingPage/ProductListingPage')),
);
export const SiteMap = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ 'bundles/App/pages/SiteMapPage/SiteMapPage')),
);
export const RushCritical = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ 'bundles/App/pages/service/RushCritical')),
);
export const FranchiseSolutions = loadable(() =>
  retry(
    () =>
      import(/* webpackPrefetch: true */ 'bundles/App/pages/service/FranchiseSolutions/FranchiseSolutions'),
  ),
);
export const Insights = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ 'bundles/App/pages/service/Insights/Insights')),
);
export const BoxDesign = loadable(() => retry(() => import('bundles/App/pages/service/BoxDesign/BoxDesign')));
export const DesignServices = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ 'bundles/App/pages/service/DesignServices/DesignServices')),
);
export const CloudEnterpriseServices = loadable(() =>
  retry(() => import('bundles/App/pages/service/CloudEnterpriseServices')),
);
export const ContactSales = loadable(() =>
  retry(() => import('bundles/App/pages/service/CloudEnterpriseServices/ContactSalesForm')),
);
export const HomePage = loadable(() => retry(() => import(/* webpackPrefetch: true */ './pages/HomePage')));

export const Success = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ './pages/ContactUsPage/Success')),
);
export const ContactUsPage = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ './pages/ContactUsPage/ContactUsPage')),
);
export const HoursAndLocation = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ './pages/Company/HoursAndLocation')),
);
export const CareersPage = loadable(() => retry(() => import('./pages/CareersPage')));
export const OurStoryPage = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ './pages/AboutUsPage')),
);
export const OrderDetailsPage = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ './pages/OrderDetailsPage/OrderDetailsPage')),
);
export const PaperShortagePage = loadable(() =>
  retry(() => import('./pages/PaperShortagePage/PaperShortagePage')),
);
export const EditPassword = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ './pages/auth/EditPassword/EditPassword')),
);
export const FourOhFour = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ './pages/FourOhFour/404')),
);
export const RedirectModal = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ 'styleguide/components/RedirectModal/RedirectModal')),
);

export const preloadComponent = (componentName: string) => {
  const components = {
    ProductDetailsPage,
    CategoryPage,
    UploadPage,
    PaymentSuccessPage,
    PaymentPortalPage,
    CreditCardsPage,
    TaxExemptionsPage,
    AddressesPage,
    SettingsPage,
    ProofApprovalsPage,
    TrackingPage,
    OrderHistoryPage,
    InformationPage,
    HelpCenterPage,
    SignUpPage,
    SignInPage,
    ResetPasswordPage,
    ResendConfirmationPage,
    CartPage,
    CheckoutPage,
    ProductListingPage,
    SiteMap,
    RushCritical,
    FranchiseSolutions,
    Insights,
    BoxDesign,
    DesignServices,
    CloudEnterpriseServices,
    HomePage,
    Success,
    ContactUsPage,
    HoursAndLocation,
    CareersPage,
    OurStoryPage,
    OrderDetailsPage,
    ContactSales,
    EditPassword,
    PaperShortagePage,
    FourOhFour,
  };

  const component = components[componentName];

  if (component) {
    component.preload();
  }
};
