import * as React from 'react';

import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';
import colors from 'styleguide/styles/colors';

const IconCart = ({
  title = 'Cart',
  color = 'dark',
  viewBoxWidth = 25,
  viewBoxHeight = 23,
  ...otherProps
}: SvgProps) => (
  <SvgIcon {...{ title, viewBoxWidth, viewBoxHeight, color, ...otherProps }}>
    <g stroke={colors[color]} strokeWidth="2" fill="none" fillRule="evenodd">
      <circle cx="10.5" cy="20.5" r="2" />
      <circle cx="20.5" cy="20.5" r="2" />
      <path d="M0 .5h4.05l3.664 15h15.393l1.393-11H5.05" />
    </g>
  </SvgIcon>
);

export default IconCart;
