import * as React from 'react';
import css from './MobileNav.scss';
import cn from 'classnames';
import { FaUserAlt } from '@react-icons/all-files/fa/FaUserAlt';
import { getUsersSignOut } from 'api/userAuthentication';
import { preloadComponent } from 'app/bundles/App/DynamicPages.imports-loadable';
import { contactPhone } from 'app/constants';
import logo from 'assets/images/printivity-logo-mobile.png';
import { ourStoryPath, servicePath, signInPath } from 'app/bundles/App/routes';
import UserContext from 'contexts/UserContextContainer/UserContext';
import { updateCsrfToken } from 'libs/utils/api';
import { Status } from 'libs/utils/api/types';
import Sidebar from './components/SideNav/SideNav';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import Image from 'styleguide/components/Image/Image';
import Link from 'styleguide/components/Links/Link';
import CartIcon from 'styleguide/components/Nav/CartIcon/CartIcon';
import { IconClose } from 'styleguide/icons';
import IconBurger from 'styleguide/icons/IconBurger';
import IconArrowDown from 'styleguide/icons/IconArrowDown';
import { UiColor } from 'styleguide/styles/colors';
import toasts from 'utils/toast';
import MobileMenuBlock from './components/MobileMenuBlock/MobileMenuBlock';
import MobileMenuItem from './components/MobileMenuItem/MobileMenuItem';
import MobileNavContext, { MobileNavContextType } from './MobileNavContext';
import ButtonAsNativeLink from 'styleguide/components/Button/ButtonAsNativeLink';
import { clickEmailUs, clickGraphicDesignLink, clickSignUpPdp } from 'api/gtm';
import { isProductPage } from 'libs/utils/pageUtils';
import AppContext from 'contexts/AppContext/AppContext';
import AccountMenuPopover, {
  userAccountNavigations,
} from '../Tailwind/AccountMenuPopover/AccountMenuPopover';

const Icon = (isOpen: boolean, theme: UiColor = 'white') => (
  <IconArrowDown color={theme} className={cn('!h-2 !w-2 cursor-pointer', isOpen ? '-rotate-180' : '')} />
);

const AllProductsMenuItem = ({ isOpen }) => (
  <MobileMenuItem theme="white" title="All Products" bold Icon={Icon(isOpen, 'dark')} dontCloseOnClick />
);

const MarketingMenuItem = ({ isOpen }) => (
  <MobileMenuItem title="Marketing" theme="lightBlue" Icon={Icon(isOpen, 'dark')} dontCloseOnClick />
);

const AccountMenuItem = ({ isOpen }) => (
  <MobileMenuItem theme="white" title="Account" bold Icon={Icon(isOpen, 'dark')} dontCloseOnClick />
);

const BusinessCardsMenuItem = ({ isOpen }) => (
  <MobileMenuItem title="Business Cards" theme="lightBlue" Icon={Icon(isOpen, 'dark')} dontCloseOnClick />
);

const BooksAndBookletsMenuItem = ({ isOpen }) => (
  <MobileMenuItem title="Books & Booklets" theme="lightBlue" Icon={Icon(isOpen, 'dark')} dontCloseOnClick />
);

const CopiesMenuItem = ({ isOpen }) => (
  <MobileMenuItem title="Copies" theme="lightBlue" Icon={Icon(isOpen, 'dark')} dontCloseOnClick />
);

const SignageMenuItem = ({ isOpen }) => (
  <MobileMenuItem title="Signage" theme="lightBlue" Icon={Icon(isOpen, 'dark')} dontCloseOnClick />
);

const StickersMenuItem = ({ isOpen }) => (
  <MobileMenuItem title="Stickers" theme="lightBlue" Icon={Icon(isOpen, 'dark')} dontCloseOnClick />
);

const BoxesMenuItem = ({ isOpen }) => (
  <MobileMenuItem title="Boxes" theme="lightBlue" Icon={Icon(isOpen, 'dark')} dontCloseOnClick />
);

const BindersMenuItem = ({ isOpen }) => (
  <MobileMenuItem title="Binders" theme="lightBlue" Icon={Icon(isOpen, 'dark')} dontCloseOnClick />
);

const ShopByNeedMenuItem = ({ isOpen }) => (
  <MobileMenuItem title="Shop By Need" theme="white" bold Icon={Icon(isOpen, 'dark')} dontCloseOnClick />
);

const MarketingMaterialsMenuItem = ({ isOpen }) => (
  <MobileMenuItem
    theme="white"
    title="Marketing Materials"
    bold
    Icon={Icon(isOpen, 'dark')}
    dontCloseOnClick
  />
);

const WhiteThemeBooksAndBookletsMenuItem = ({ isOpen }) => (
  <MobileMenuItem theme="white" title="Books & Booklets" bold Icon={Icon(isOpen, 'dark')} dontCloseOnClick />
);

const ServicesMenuItem = ({ isOpen }) => (
  <MobileMenuItem theme="white" title="Services" bold Icon={Icon(isOpen, 'dark')} dontCloseOnClick />
);

const MobileNav = () => {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const { logOut, currentUser } = React.useContext(UserContext);
  const location = useLocation();
  const appContext = React.useContext(AppContext);

  const closeMenu = React.useCallback(() => {
    setMenuOpen(false);
  }, [setMenuOpen]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const mobileNavContextPayload: MobileNavContextType = React.useMemo(
    () => ({
      isOpen: menuOpen,
      onClose: closeMenu,
    }),
    [menuOpen, closeMenu],
  );

  const handleSignOut = () => {
    getUsersSignOut().then(res => {
      if (res.status === Status.Ok) {
        updateCsrfToken(res.payload.csrfToken);
        logOut();
        toasts.create('Successfully signed out.', 'info');
      } else {
        toasts.error();
      }
    });
  };
  return (
    <div className="block w-full">
      <MobileNavContext.Provider value={mobileNavContextPayload}>
        <div className={css.MobileNav}>
          <IconBurger onClick={toggleMenu} className="cursor-pointer" data-cy="mobile-menu-burger-btn" />
          <Link to="/" color="none" underline="none">
            <Image
              url={logo}
              title="Printivity"
              alt="Printivity"
              className="ml-5 h-12"
              fetchpriority="high"
            />
          </Link>
          <div className={`flex ${currentUser ? 'items-start' : 'items-center'}`}>
            {currentUser ? (
              <AccountMenuPopover
                buttonContent={<FaUserAlt className="!h-4 !w-4 cursor-pointer text-blue-300" />}
                buttonWrapperProps={{
                  className: 'flex items-center flex-row mr-1',
                  role: 'button',
                  tabIndex: 0,
                }}
                chevronClassName="!text-blue-300"
              />
            ) : (
              <Link to={signInPath} color="black" underline="none" ariaLabel="user">
                <FaUserAlt className="mr-5 !h-4 !w-4 cursor-pointer text-blue" />
              </Link>
            )}
            <CartIcon />
          </div>
        </div>
        <div>
          <Sidebar isOpen={menuOpen} setMenuOpen={setMenuOpen}>
            <div
              className="overflow-y-auto
                h-full
                bg-white
                [scrollbar-width:thin]
                scrollbar-track:!bg-blue-50
                scrollbar-thumb:!bg-blue
                scrollbar-thumb:!rounded-lg
                scrollbar-thumb:hover:!bg-blue-900
                scrollbar:!w-2.5"
            >
              <MobileMenuItem
                Img={<Image url={logo} title="Printivity" alt="Printivity" className="w-20" />}
                Icon={<IconClose color="dark" className="!h-4 !w-4 cursor-pointer" onClick={closeMenu} />}
                linkTo="/"
                theme="white"
              />
              <div className="!flex items-start border-b border-solid border-gray-50 pb-5 pl-5">
                <FaUserAlt className="mr-3 !h-4 !w-4 cursor-pointer" />
                <div className="paragraph-medium-mobile flex w-full">
                  {currentUser && (
                    <div className="flex w-full justify-between">
                      {(currentUser?.firstName && `Hi ${currentUser.firstName}!`) || 'Account'}
                      <div
                        onClick={e => {
                          e.preventDefault();
                          handleSignOut();
                        }}
                        onKeyDown={e => {
                          e.preventDefault();
                          handleSignOut();
                        }}
                        className="hvMedium pr-4 text-sm text-gray-300 hover:cursor-pointer"
                        role="button"
                        tabIndex={0}
                      >
                        Sign out
                      </div>
                    </div>
                  )}
                  {!currentUser && (
                    <RouterLink
                      to={signInPath}
                      className="hover:cursor-pointer text-default no-underline"
                      onClick={closeMenu}
                    >
                      Sign In
                    </RouterLink>
                  )}
                </div>
              </div>
              {!currentUser && (
                <div className="!flex items-center justify-between border-b border-solid border-gray-50 py-4 pl-5 pr-2">
                  <div className="hvMedium text-sm text-gray-500">New customer?</div>
                  <ButtonAsNativeLink
                    onClick={() => {
                      if (isProductPage(location.pathname, appContext.store.cmsPages)) {
                        clickSignUpPdp();
                      }
                      closeMenu();
                    }}
                    buttonType="link"
                    color="orange"
                    target={{
                      pathname: '/sign-up',
                      state: { from: location.pathname },
                    }}
                    size="sm"
                  >
                    Sign Up
                  </ButtonAsNativeLink>
                </div>
              )}
              <MobileMenuBlock noPadding title={AllProductsMenuItem}>
                <MobileMenuBlock title={MarketingMenuItem}>
                  <MobileMenuItem
                    title="Brochures"
                    withBorder
                    thin
                    theme="white"
                    linkTo="/marketing/brochures"
                  />
                  <MobileMenuItem
                    title="Postcards"
                    withBorder
                    thin
                    theme="white"
                    linkTo="/marketing/postcards"
                  />
                  <MobileMenuItem title="Flyers" withBorder thin theme="white" linkTo="/marketing/flyers" />
                  <MobileMenuItem title="Folders" withBorder thin theme="white" linkTo="/marketing/folders" />
                  <MobileMenuItem
                    title="Rack Cards"
                    withBorder
                    thin
                    theme="white"
                    linkTo="/marketing/rack-cards"
                  />
                  <MobileMenuItem title="Menus" withBorder thin theme="white" linkTo="/marketing/menus" />
                  <MobileMenuItem
                    title="Door Hangers"
                    withBorder
                    thin
                    theme="white"
                    linkTo="/marketing/door-hangers"
                  />
                  <MobileMenuItem
                    title="Calendars"
                    thin
                    theme="white"
                    withBorder
                    linkTo="/marketing/calendars"
                  />
                  <MobileMenuItem
                    title="Bookmarks"
                    thin
                    theme="white"
                    withBorder
                    linkTo="/marketing/bookmarks"
                  />
                  <MobileMenuItem title="Stickers" thin theme="white" withBorder linkTo="/stickers" />
                  <MobileMenuItem title="Posters" thin theme="white" withBorder linkTo="/signage/posters" />
                  <MobileMenuItem
                    title="Yard Signs"
                    thin
                    theme="white"
                    withBorder
                    linkTo="/signage/yard-signs"
                  />
                  <MobileMenuItem title="Banners" thin theme="white" withBorder linkTo="/signage/banners" />
                </MobileMenuBlock>
                <MobileMenuBlock title={BusinessCardsMenuItem}>
                  <MobileMenuItem
                    title="Classic Business Cards"
                    withBorder
                    thin
                    theme="white"
                    linkTo="/business-cards/classic-business-cards"
                  />
                  <MobileMenuItem
                    title="Kraft Business Cards"
                    withBorder
                    thin
                    theme="white"
                    linkTo="/business-cards/kraft-business-cards"
                  />
                  <MobileMenuItem
                    title="Linen Business Cards"
                    withBorder
                    thin
                    theme="white"
                    linkTo="/business-cards/linen-business-cards"
                  />
                  <MobileMenuItem
                    title="Pearl Business Cards"
                    withBorder
                    thin
                    theme="white"
                    linkTo="/business-cards/pearl-business-cards"
                  />
                  <MobileMenuItem
                    title="Triple Layer Business Cards"
                    withBorder
                    thin
                    theme="white"
                    linkTo="/business-cards/triple-layer-business-cards"
                  />
                  <MobileMenuItem
                    title="Painted Edge Business Cards"
                    thin
                    theme="white"
                    withBorder
                    linkTo="/business-cards/painted-edge-business-cards"
                  />
                </MobileMenuBlock>
                <MobileMenuBlock title={BooksAndBookletsMenuItem}>
                  <MobileMenuItem
                    title="Saddle-Stitched Booklets"
                    withBorder
                    thin
                    theme="white"
                    linkTo="/booklets/saddle-stitched-booklets"
                  />
                  <MobileMenuItem
                    title="Spiral Bound Booklets"
                    withBorder
                    thin
                    theme="white"
                    linkTo="/booklets/spiral-bound-booklets"
                  />
                  <MobileMenuItem
                    title="Wire-O Booklets"
                    withBorder
                    thin
                    theme="white"
                    linkTo="/booklets/wire-o-booklets"
                  />
                  <MobileMenuItem
                    title="Perfect Bound Books"
                    withBorder
                    thin
                    theme="white"
                    linkTo="/books/perfect-bound-books"
                  />
                  <MobileMenuItem
                    title="PUR Perfect Bound Books"
                    withBorder
                    thin
                    theme="white"
                    linkTo="/books/pur-perfect-bound-books"
                  />
                  <MobileMenuItem
                    title="Hardcover Books"
                    withBorder
                    thin
                    theme="white"
                    linkTo="/books/hardcover-books"
                  />
                  <MobileMenuItem
                    title="Coloring Books"
                    withBorder
                    thin
                    theme="white"
                    linkTo="/books/coloring-books"
                  />
                  <MobileMenuItem
                    title="Comic Books"
                    withBorder
                    thin
                    theme="white"
                    linkTo="/books/comic-books"
                  />
                </MobileMenuBlock>
                <MobileMenuBlock title={CopiesMenuItem}>
                  <MobileMenuItem
                    title="Color Copies"
                    withBorder
                    thin
                    theme="white"
                    linkTo="/copies/color-copies"
                  />
                  <MobileMenuItem
                    title="Black & White Copies"
                    thin
                    theme="white"
                    linkTo="/copies/black-and-white-copies"
                  />
                </MobileMenuBlock>
                <MobileMenuBlock title={SignageMenuItem}>
                  <MobileMenuItem
                    title="Mini Posters"
                    withBorder
                    thin
                    theme="white"
                    linkTo="/signage/mini-posters"
                  />
                  <MobileMenuItem title="Posters" withBorder thin theme="white" linkTo="/signage/posters" />
                  <MobileMenuItem
                    title="Yard Signs"
                    withBorder
                    thin
                    theme="white"
                    linkTo="/signage/yard-signs"
                  />
                  <MobileMenuItem title="Banners" thin theme="white" linkTo="/signage/banners" />
                </MobileMenuBlock>
                <MobileMenuBlock title={StickersMenuItem}>
                  <MobileMenuItem
                    title="Circle Stickers"
                    withBorder
                    thin
                    theme="white"
                    linkTo="/stickers/circle-stickers"
                  />
                  <MobileMenuItem
                    title="Oval Stickers"
                    withBorder
                    thin
                    theme="white"
                    linkTo="/stickers/oval-stickers"
                  />
                  <MobileMenuItem
                    title="Square Stickers"
                    withBorder
                    thin
                    theme="white"
                    linkTo="/stickers/square-stickers"
                  />
                  <MobileMenuItem
                    title="Rectangle Stickers"
                    thin
                    theme="white"
                    linkTo="/stickers/rectangle-stickers"
                  />
                </MobileMenuBlock>
                <MobileMenuBlock title={BoxesMenuItem}>
                  <MobileMenuItem title="Shipping Boxes" thin theme="white" linkTo="/boxes/shipping-boxes" />
                </MobileMenuBlock>
                <MobileMenuBlock title={BindersMenuItem}>
                  <MobileMenuItem
                    title="Three Ring Binders"
                    thin
                    theme="white"
                    linkTo="/binders/three-ring-binders"
                  />
                  <MobileMenuItem
                    title="Binder Documents"
                    thin
                    theme="white"
                    linkTo="/binders/binder-documents"
                  />
                </MobileMenuBlock>
                <MobileMenuItem
                  title="Show All Products"
                  withBorder
                  thin
                  theme="lightBlue"
                  linkTo="/products"
                  className="!border-t"
                />
              </MobileMenuBlock>

              <MobileMenuBlock noPadding title={MarketingMaterialsMenuItem}>
                <MobileMenuItem
                  title="Brochures"
                  withBorder
                  thin
                  theme="lightBlue"
                  linkTo="/marketing/brochures"
                />
                <MobileMenuItem
                  title="Postcards"
                  withBorder
                  thin
                  theme="lightBlue"
                  linkTo="/marketing/postcards"
                />
                <MobileMenuItem title="Flyers" withBorder thin theme="lightBlue" linkTo="/marketing/flyers" />
                <MobileMenuItem
                  title="Folders"
                  withBorder
                  thin
                  theme="lightBlue"
                  linkTo="/marketing/folders"
                />
                <MobileMenuItem
                  title="Rack CardswithBorder "
                  thin
                  theme="lightBlue"
                  linkTo="/marketing/rack-cards"
                />
                <MobileMenuItem title="Menus" withBorder thin theme="lightBlue" linkTo="/marketing/menus" />
                <MobileMenuItem
                  title="Door Hangers"
                  withBorder
                  thin
                  theme="lightBlue"
                  linkTo="/marketing/door-hangers"
                />
                <MobileMenuItem
                  title="Calendars"
                  thin
                  withBorder
                  theme="lightBlue"
                  linkTo="/marketing/calendars"
                />
              </MobileMenuBlock>
              <MobileMenuBlock noPadding title={WhiteThemeBooksAndBookletsMenuItem}>
                <MobileMenuItem
                  title="Saddle-Stitched Booklets"
                  thin
                  withBorder
                  theme="lightBlue"
                  linkTo="/booklets/saddle-stitched-booklets"
                />
                <MobileMenuItem
                  title="Spiral Bound Booklets"
                  thin
                  withBorder
                  theme="lightBlue"
                  linkTo="/booklets/spiral-bound-booklets"
                />
                <MobileMenuItem
                  title="Wire-O Booklets"
                  thin
                  withBorder
                  theme="lightBlue"
                  linkTo="/booklets/wire-o-booklets"
                />
                <MobileMenuItem
                  title="Perfect Bound Books"
                  thin
                  withBorder
                  theme="lightBlue"
                  linkTo="/books/perfect-bound-books"
                />
                <MobileMenuItem
                  title="PUR Perfect Bound Books"
                  thin
                  withBorder
                  theme="lightBlue"
                  linkTo="/books/pur-perfect-bound-books"
                />
                <MobileMenuItem
                  title="Hardcover Books"
                  thin
                  withBorder
                  theme="lightBlue"
                  linkTo="/books/hardcover-books"
                />
                <MobileMenuItem
                  title="Coloring Books"
                  thin
                  withBorder
                  theme="lightBlue"
                  linkTo="/books/coloring-books"
                />
                <MobileMenuItem title="Comic Books" thin theme="lightBlue" linkTo="/books/comic-books" />
              </MobileMenuBlock>
              <MobileMenuBlock noPadding title={ShopByNeedMenuItem}>
                <MobileMenuItem
                  title="Charity and Non-Profit"
                  thin
                  withBorder
                  theme="lightBlue"
                  linkTo="/charity-and-non-profit"
                  className="!border-t border-gray-50"
                />
                <MobileMenuItem
                  title="Education and Schools"
                  thin
                  withBorder
                  theme="lightBlue"
                  linkTo="/education-and-schools"
                  className="!border-t border-gray-50"
                />
                <MobileMenuItem
                  title="Events"
                  thin
                  withBorder
                  theme="lightBlue"
                  linkTo="/events"
                  className="!border-t border-gray-50"
                />
                <MobileMenuItem
                  title="Fundraisers"
                  thin
                  withBorder
                  theme="lightBlue"
                  linkTo="/fundraisers"
                  className="!border-t border-gray-50"
                />
                <MobileMenuItem
                  title="Funerals and Memorials"
                  thin
                  withBorder
                  theme="lightBlue"
                  linkTo="/funerals-and-memorials"
                  className="!border-t border-gray-50"
                />
                <MobileMenuItem
                  title="Hospitality and Tourism"
                  thin
                  withBorder
                  theme="lightBlue"
                  linkTo="/hospitality-and-tourism"
                  className="!border-t border-gray-50"
                />
                <MobileMenuItem
                  title="Pageants"
                  thin
                  withBorder
                  theme="lightBlue"
                  linkTo="/pageants"
                  className="!border-t border-gray-50"
                />
                <MobileMenuItem
                  title="Political"
                  thin
                  withBorder
                  theme="lightBlue"
                  linkTo="/political"
                  className="!border-t border-gray-50"
                />
                <MobileMenuItem
                  title="Real Estate"
                  thin
                  withBorder
                  theme="lightBlue"
                  linkTo="/real-estate"
                  className="!border-t border-gray-50"
                />
                <MobileMenuItem
                  title="Restaurants Printing"
                  thin
                  withBorder
                  theme="lightBlue"
                  linkTo="/restaurants"
                  className="!border-t border-gray-50"
                />
                <MobileMenuItem
                  title="Retail"
                  thin
                  withBorder
                  theme="lightBlue"
                  linkTo="/retail"
                  className="!border-t border-gray-50"
                />
                <MobileMenuItem
                  title="Small Business"
                  thin
                  withBorder
                  theme="lightBlue"
                  linkTo="/small-business"
                  className="!border-t border-gray-50"
                />
                <MobileMenuItem
                  title="Sports Printing"
                  thin
                  withBorder
                  theme="lightBlue"
                  linkTo="/sports-printing"
                  className="!border-t border-gray-50"
                />
                <MobileMenuItem
                  title="Training and Presentations"
                  thin
                  withBorder
                  theme="lightBlue"
                  linkTo="/training-and-presentations"
                  className="!border-t border-gray-50"
                />
              </MobileMenuBlock>
              <MobileMenuBlock noPadding title={ServicesMenuItem}>
                <MobileMenuItem
                  title="Graphic Design Services"
                  thin
                  withBorder
                  linkTo={servicePath.designServices}
                  theme="lightBlue"
                  className="!border-t border-gray-50"
                  onClick={() =>
                    isProductPage(location.pathname, appContext.store.cmsPages) &&
                    clickGraphicDesignLink({ location: 'pdp_mobile_nav' })
                  }
                />
                <MobileMenuItem
                  title="Rush critical"
                  thin
                  withBorder
                  linkTo={servicePath.rushCriticalPath}
                  theme="lightBlue"
                  className="!border-t border-gray-50"
                />
                <MobileMenuItem
                  title="Franchise Solutions"
                  thin
                  withBorder
                  linkTo={servicePath.franchiseSolutionsPath}
                  theme="lightBlue"
                  className="!border-t border-gray-50"
                />
                <MobileMenuItem
                  title="Box Design"
                  thin
                  withBorder
                  linkTo={servicePath.boxDesignPath}
                  theme="lightBlue"
                  className="!border-t border-gray-50"
                />
              </MobileMenuBlock>
              <MobileMenuItem title="Our Story" linkTo={ourStoryPath} theme="white" bold />
              {currentUser ? (
                <MobileMenuBlock noPadding title={AccountMenuItem}>
                  <>
                    {userAccountNavigations().map(({ name, href }) => (
                      <MobileMenuItem
                        title={name}
                        thin
                        withBorder
                        theme="white"
                        linkTo={href}
                        className="!border-t border-gray-50"
                      />
                    ))}
                    <MobileMenuItem
                      title="Sign Out"
                      theme="white"
                      onClick={e => {
                        e.preventDefault();
                        handleSignOut();
                      }}
                      className="!border-t border-gray-50"
                    />
                  </>
                </MobileMenuBlock>
              ) : null}
            </div>
            <div className="mb-8 !flex flex-col items-center">
              <a
                className="paragraph-mobile cursor-pointer items-center bg-shades-0
              text-default underline transition duration-300 ease-in-out"
                href="tel:1-877-649-5463"
              >
                {contactPhone}
              </a>
              <RouterLink
                to="/help-center"
                className="paragraph-mobile mt-6 cursor-pointer items-center
              bg-shades-0 text-default no-underline transition duration-300 ease-in-out"
                onMouseOver={() => preloadComponent('HelpCenterPage')}
              >
                Help Center
              </RouterLink>
              <RouterLink
                to="/contact-us"
                className={cn(
                  'mt-6 text-default transition duration-300 ease-in-out ' +
                    'paragraph-mobile cursor-pointer items-center bg-shades-0 no-underline',
                )}
                onMouseOver={() => preloadComponent('ContactUsPage')}
                onClick={() =>
                  isProductPage(location.pathname, appContext.store.cmsPages) &&
                  clickEmailUs({ location: 'pdp_mobile_nav' })
                }
              >
                Contact Us
              </RouterLink>
            </div>
          </Sidebar>
        </div>
      </MobileNavContext.Provider>
    </div>
  );
};

export default MobileNav;
