import * as React from 'react';
import cn from 'classnames';
import Grid from 'styleguide/components/Grid/Grid';
import IfTabletOrMobile from 'styleguide/components/Conditional/IfTabletOrMobile';
import IfDesktop from 'styleguide/components/Conditional/IfDesktop';
import { IconCheckmark } from 'styleguide/icons';
import startCase from 'lodash-es/startCase';
import { FileData } from '../UploadAreaWithStatus/UploadAreaWithStatus';
import { LineItem } from 'api/orders/types';
import Chip from 'styleguide/components/Chip/Chip';
import { fileStateToColor } from 'utils/orderStateMap';
import { FaTrashAlt } from '@react-icons/all-files/fa/FaTrashAlt';

interface Props {
  filesInUpload: FileData[];
  removeFile?: (fileKey: string) => void;
  lineItem?: LineItem;
  fileVersionState?: string;
}

const FileStatus = (props: Props) => {
  const { filesInUpload, removeFile, lineItem, fileVersionState } = props;
  const getFileVersionStatus = (state: string) => startCase(state.split('_').join(' '));

  const isError = fileStatus => fileStatus.message === 'Error';

  return (
    <Grid.Container className="z-1 relative md:!w-[600px]">
      <Grid noPadding>
        <Grid.Row>
          <Grid.Col className="mt-4 flex items-center justify-between md:mb-2" sm={12} md={12}>
            <div className="flex items-center justify-between">
              <IfDesktop className="!w-fit">
                <p
                  data-cy="uploadedFilesStatus"
                  className="flex-grow text-left font-hvBold text-lg leading-4 tracking-tight"
                >
                  {`${filesInUpload.length} File${filesInUpload.length > 1 ? 's' : ''} uploaded to your ${
                    lineItem ? lineItem.product.name : ''
                  } project`}
                </p>
              </IfDesktop>
              <IfTabletOrMobile className="flex flex-grow justify-around">
                <p
                  data-cy="uploadedFilesStatus"
                  className="flex-grow text-left font-hvBold text-lg leading-4 tracking-tight"
                >
                  {`${filesInUpload.length} File${filesInUpload.length > 1 ? 's' : ''} uploaded to your ${
                    lineItem ? lineItem.product.name : ''
                  } project`}
                </p>
              </IfTabletOrMobile>
              {fileVersionState && (
                <Chip
                  title={getFileVersionStatus(fileVersionState)}
                  textColor={
                    fileStateToColor[getFileVersionStatus(fileVersionState)]?.textColor || 'text-neutral-50'
                  }
                  backgroundColor={
                    fileStateToColor[getFileVersionStatus(fileVersionState)]?.backgroundColor ||
                    'bg-neutral-400'
                  }
                />
              )}
            </div>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col sm={12} md={12}>
            {filesInUpload.length > 0 &&
              filesInUpload.map(fileStatus => (
                <Grid.Row className="mb-[7px] md:mb-[10px]" key={fileStatus.key}>
                  <Grid.Col className="flex items-center" sm={12}>
                    <div
                      className={cn(
                        'flex flex-grow items-center',
                        isError(fileStatus) && 'flex-col items-start',
                      )}
                    >
                      {!!fileStatus.verified && (
                        <div className="mr-2 flex !h-3 !w-3 items-center justify-center rounded-xl bg-green-500">
                          <IconCheckmark
                            data-cy={`uploadDoneCheckmark-${fileStatus.key}`}
                            className="!h-2 !w-2"
                            size="sm"
                            color="white"
                          />
                        </div>
                      )}
                      {isError(fileStatus) && (
                        <p className="font-hvMedium text-sm text-red-700">
                          Ops, an error occurred uploading your file. Please try again.
                        </p>
                      )}
                      <div className="ml-1 flex-grow text-left">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={fileStatus.url}
                          className={cn(
                            !!fileStatus.url && !!fileStatus === true
                              ? 'flex-grow break-all text-blue underline'
                              : 'flex-grow cursor-default break-all no-underline',
                          )}
                        >
                          <p
                            className={cn(
                              !!fileStatus.url && !!fileStatus === true && 'text-blue',
                              isError(fileStatus) && 'text-gray-300',
                              'm-0 flex-grow text-sm leading-5 md:leading-4',
                            )}
                            key={fileStatus.key}
                          >
                            {fileStatus.name}
                          </p>
                        </a>
                      </div>
                      {removeFile && !isError(fileStatus) && (
                        <div
                          className={cn(
                            'inline-flex cursor-pointer items-center',
                            fileStatus.verified ? '' : 'md:hidden',
                          )}
                          onClick={() => removeFile(fileStatus.key)}
                          onKeyDown={event => {
                            if (event.key === 'Enter') {
                              removeFile(fileStatus.key);
                            }
                          }}
                          role="button"
                          tabIndex={0}
                        >
                          <FaTrashAlt className="mr-2 h-3 w-3" color="red" />
                          <span className="mt-1 font-hvMedium text-sm text-red-500">Remove</span>
                        </div>
                      )}
                    </div>
                  </Grid.Col>
                  {!!fileStatus.verified === false && fileStatus.status && fileStatus.status !== 100 ? (
                    <Grid.Col className="flex items-center">
                      <div className="mb-1 mt-px min-h-[4px] flex-[5] bg-blue-50 p-0 md:mb-0 md:min-h-[5px]">
                        <div
                          className="min-h-[4px] bg-blue-500 md:min-h-[5px]"
                          style={{ width: `${fileStatus.status}%` }}
                        />
                      </div>
                    </Grid.Col>
                  ) : (
                    <Grid.Col sm={12} md={8} />
                  )}
                </Grid.Row>
              ))}
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </Grid.Container>
  );
};

export default FileStatus;
