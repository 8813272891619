import * as React from 'react';

export interface MobileNavContextType {
  isOpen: boolean;
  onClose: () => void;
}

const MobileNavContext = React.createContext<MobileNavContextType>(null);

export default MobileNavContext;
